import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SelectPropertyId from "components/SelectPropertyId";
import Loader from "components/loader";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, property_id, view_id, loading } = useSelector((state) => state.auth);
  if(loading) return <Loader />;
  if (!isAuthenticated) return <Navigate to="/login" />;
  if (!property_id && !view_id) return <SelectPropertyId />;
  return children;
};

export default PrivateRoute;
