import React, { Suspense } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import MainLayout from "./layouts/mainLayout";
import AdminLayout from "./layouts/adminLayout";
import adminRoutes from "./adminRoutes";
import routes from "./routes";
import { Login, Register, ResetPassword, ForgotPassword, EmailVerification, Logout } from "./pages";
import PrivateRoute from "./utils/privateRoute";
import AdminRoute from "./utils/adminRoute";
import Loader from "components/loader";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<MainLayout />}>
          {routes.map((route, i) => (
            <React.Fragment key={i}>
              {route?.subMenu ? (
                <React.Fragment>
                  {route.subMenu.map((subRoute, i) => (
                    <Route
                      key={i}
                      path={subRoute.path}
                      element={
                        <Suspense fallback={<Loader />}>
                          <PrivateRoute>
                            <subRoute.component />
                          </PrivateRoute>
                        </Suspense>
                      }
                    />
                  ))}
                </React.Fragment>
              ) : (
                <Route
                  path={route.path}
                  element={
                    <Suspense fallback={<Loader />}>
                      <PrivateRoute>
                        <route.component />
                      </PrivateRoute>
                    </Suspense>
                  }
                />
              )}
            </React.Fragment>
          ))}
        </Route>
        <Route element={<AdminLayout />}>
          {adminRoutes.map((route, i) => (
            <React.Fragment key={i}>
              {route?.subMenu ? (
                <React.Fragment>
                  {route.subMenu.map((subRoute, i) => (
                    <Route
                      key={i}
                      path={subRoute.path}
                      element={
                        <Suspense fallback={<Loader />}>
                          <AdminRoute>
                            <subRoute.component />
                          </AdminRoute>
                        </Suspense>
                      }
                    />
                  ))}
                </React.Fragment>
              ) : (
                <Route
                  path={route.path}
                  element={
                    <Suspense fallback={<Loader />}>
                      <AdminRoute>
                        <route.component />
                      </AdminRoute>
                    </Suspense>
                  }
                />
              )}
            </React.Fragment>
          ))}
        </Route>
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/register"
          element={
            <Suspense fallback={<Loader />}>
              <Register />
            </Suspense>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <Suspense fallback={<Loader />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="/reset-password/:token"
          element={
            <Suspense fallback={<Loader />}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          path="/verify-email/:token"
          element={
            <Suspense fallback={<Loader />}>
              <EmailVerification />
            </Suspense>
          }
        />
        <Route
          path="/logout"
          element={
            <Suspense fallback={<Loader />}>
              <Logout />
            </Suspense>
          }
        />
        <Route path="*" element={<Navigate to="/audience" />} />
      </Routes>
    </Router>
  );
}

export default App;
