import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminRoute = ({ children }) => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  if (!user?.role || user?.role !== "admin") {
    return <Navigate to="/login" />;
  }
  return children;
};

export default AdminRoute;
