import icon from "../assets/images/analytify-icon.svg";
import React from "react";

const Loader = () => {
  return (
    <div className="loader">
      <div className="loading-img"><img src={icon} alt="analytify-icon" /></div>
    </div>
  );
};

export default Loader;
