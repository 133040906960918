import React from "react";

const Footer = () => {
	const year =  new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="wpb-container">
        <p className="copyright">
          Copyright © 2014 - {year} Analytify. Analytify is a trademark of
          Analytify LLC.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
