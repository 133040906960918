import { Users, Dashboard } from "./pages";

const routes = [
  {
    path: "/admin/dashboard",
    label: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/admin/users",
    label: "Users",
    component: Users,
  },
];

export default routes;
