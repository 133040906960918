import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const Api = createApi({
  reducerPath: "apiData",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body: body,
      }),
    }),
    register: builder.mutation({
      query: (body) => ({
        url: "/register",
        method: "POST",
        body: body,
      }),
    }),
    loginWithGoogle: builder.mutation({
      query: (body) => ({
        url: "/login/google",
        method: "POST",
        body: body,
      }),
    }),
    logout: builder.mutation({
      query: (body) => ({
        url: "/logout",
        method: "POST",
        body: body,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "/forgot-password",
        method: "POST",
        body: body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "/reset-password",
        method: "POST",
        body: body,
      }),
    }),
    verifyEmail: builder.mutation({
      query: (body) => ({
        url: "/verify-email",
        method: "POST",
        body: body,
      }),
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: "/update-profile",
        method: "POST",
        body: body,
      }),
    }),
    getUsers: builder.query({
      query: () => "/users",
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: `/users/${body.id}`,
        method: "POST",
        body: body,
      }),
    }),
    refreshToken: builder.mutation({
      query: () => "/refresh-token",
    })
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useLoginWithGoogleMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyEmailMutation,
  useUpdateProfileMutation,
  useGetUsersQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useRefreshTokenMutation,
} = Api;
