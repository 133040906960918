import React, { useState } from "react";
import { lazy } from "react";
import { Datepicker } from "../components";  // Adjust the path if needed

// Lazy loaded components
const Login = lazy(() => import("./login"));
const Audience = lazy(() => import("./audience"));
const Register = lazy(() => import("./register"));
const Conversions = lazy(() => import("./conversions"));
const Authors = lazy(() => import("./engagement/authors"));
const Demographics = lazy(() => import("./engagement/demographics"));
const Dimensions = lazy(() => import("./engagement/dimensions"));
const SearchTerms = lazy(() => import("./engagement/searchTerms"));
const Woocommerce = lazy(() => import("./monetisation/woocommerce"));
const Edd = lazy(() => import("./monetisation/edd"));
const RealTime = lazy(() => import("./realTime"));
const Campaigns = lazy(() => import("./acquisition/campaigns"));
const Goals = lazy(() => import("./acquisition/goals"));
const ForgotPassword = lazy(() => import("./forgotPassword"));
const ResetPassword = lazy(() => import("./resetPassword"));
const EmailVerification = lazy(() => import("./emailVerification"));
const Profile = lazy(() => import("./profile"));
const Users = lazy(() => import("./users"));
const Dashboard = lazy(() => import("./dashboard"));
const Logout = lazy(() => import("./logout"));

// Higher-Order Component to include Datepicker
const withDatepicker = (Component) => {
  return () => {
    // Initialize dateRange from localStorage or default to [new Date(), new Date()]
    const [dateRange, setDateRange] = useState(() => {
      const storedRange = localStorage.getItem("dateRange");
      return storedRange ? storedRange : [new Date(), new Date()];
    });

    const handleViewStats = (range) => {
      // Update state and save to localStorage
      setDateRange(range);
      localStorage.setItem("dateRange", range);
    };

    return (
      <>
        <Datepicker onDateChange={handleViewStats} />
        <Component dateRange={dateRange} />
      </>
    );
  };
};

// Export components with Datepicker
export const WrappedAudience = withDatepicker(Audience);
export const WrappedConversions = withDatepicker(Conversions);
export const WrappedAuthors = withDatepicker(Authors);
export const WrappedDemographics = withDatepicker(Demographics);
export const WrappedDimensions = withDatepicker(Dimensions);
export const WrappedSearchTerms = withDatepicker(SearchTerms);
export const WrappedWoocommerce = withDatepicker(Woocommerce);
export const WrappedEdd = withDatepicker(Edd);
export const WrappedRealTime = withDatepicker(RealTime);
export const WrappedCampaigns = withDatepicker(Campaigns);
export const WrappedGoals = withDatepicker(Goals);

// Export components without Datepicker
export { 
  Login, 
  Register, 
  ForgotPassword, 
  ResetPassword, 
  EmailVerification, 
  Profile, 
  Users, 
  Dashboard, 
  Logout 
};
