import React from "react";
import { Outlet } from "react-router-dom";
import { Header, Footer } from "../components";
import routes from "../adminRoutes";

const AdminLayout = () => {
  return (
    <div className="main">
      <div className="main-content">
      <Header routes={routes} />
        <div className="wpb-container">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminLayout;
