import {
  WrappedAudience as Audience,
  WrappedConversions as Conversions,
  WrappedRealTime as RealTime,
  WrappedCampaigns as Campaigns,
  WrappedGoals as Goals,
  WrappedWoocommerce as Woocommerce,
  WrappedSearchTerms as SearchTerms,
  WrappedDemographics as Demographics,
  WrappedDimensions as Dimensions,
  // WrappedAuthors as Authors,
  Profile
} from "./pages";

const routes = [
  {
    path: "/audience",
    label: "Audience",
    desc: "Overview",
    component: Audience,
  },
  {
    label: "Conversions",
    desc: "All Events",
    subMenu: [
      {
        path: "/conversions/forms-tracking",
        label: "Forms Tracking",
        desc: "View Forms Analytics",
        component: Conversions,
      },
      {
        path: "/conversions/events-tracking",
        label: "Events Tracking",
        desc: "Affiliates, clicks and links tracking",
        component: Conversions,
      },
    ],
  },
  {
    label: "Acquisition",
    desc: "Goals, Campaigns",
    subMenu: [
      {
        path: "/acquisition/campaigns",
        label: "Campaigns",
        desc: "UTM Overview",
        component: Campaigns,
      },
      {
        path: "/acquisition/goals",
        label: "Goals",
        desc: "Overview",
        component: Goals,
      },
    ],
  },
  {
    label: "Monetisation",
    desc: "Overview",
    subMenu: [
      {
        path: "/monetisation/woocommerce",
        label: "E-Commerce",
        desc: "E-Commerce Stats",
        component: Woocommerce,
      },
      // {
      //   path: "/monetisation/edd",
      //   label: "EDD",
      //   desc: "Checkout behavior",
      //   component: Edd,
      // },
    ],
  },
  {
    label: "Engagement",
    desc: "What is this",
    subMenu: [
      // {
      //   path: "/engagement/authors",
      //   label: "Authors",
      //   desc: "Authors Content Overview",
      //   component: Authors,
      // },
      {
        path: "/engagement/demographics",
        label: "Demographics",
        desc: "Age & Gender Overview",
        component: Demographics,
      },
      {
        path: "/engagement/search-terms",
        label: "Search Terms",
        desc: "On Site Searches",
        component: SearchTerms,
      },
      {
        path: "/engagement/dimensions",
        label: "Dimensions",
        desc: "On Site Searches",
        component: Dimensions,
      },
    ],
  },
  {
    path: "/real-time",
    label: "Real Time",
    desc: "Live Stats",
    component: RealTime,
  },
  {
    path: "/profile",
    component: Profile,
    hideInMenu: true,
  },
];

export default routes;
