import React from "react";
import DatePickerInput from "./datePickerInput";

const DatePicker = ({ onDateChange }) => {
const analyticsData = JSON.parse(localStorage.getItem("analyticsData"));
const streamUrl = analyticsData ? analyticsData?.stream_url?.replace(/"/g, "") : "";
const streamName = analyticsData ? analyticsData?.stream_name?.replace(/"/g, "") : "";

// Extract the last part of the URL path
const currentPath = window.location.hash.replace("#/", "").split("/");
const lastSegment = currentPath[currentPath.length - 1]; // Get last segment

// Format the title correctly
const formattedTitle = lastSegment
  .replace(/-/g, " ") // Replace hyphens with spaces
  .replace(/\b\w/g, (char) => char.toUpperCase()) + " Dashboard"; // Capitalize first letter

return (
  <div className="date-picker-section">
    <div className="info">
      <h4 className="title">{formattedTitle}</h4>
      <p className="desc">
        <a href={streamUrl}><span>{streamUrl}</span></a> ({streamName})
      </p>
    </div>
      <div className="date-picker-wrapper">
        <DatePickerInput onDateChange={onDateChange} />
      </div>
    </div>
  );
};

export default DatePicker;
