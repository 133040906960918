import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  loading: false,
  access_token: null,
  property_id: null,
  property_name: null,
  view_id: null,
  view_name: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.access_token = action.payload.access_token || null;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.access_token = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUserName: (state, action) => {
      state.user.name = action.payload;
    },
    setAnalytics: (state, action) => {
      state.view_id = action.payload.view_id;
      state.view_name = action.payload.view_name;
      state.property_id = action.payload.property_id;
      state.property_name = action.payload.property_name;
    },
    setAccessToken: (state, action) => {
      state.access_token = action.payload;
    },
  },
});

export const { login, logout, setError, setLoading, setSuccess, setUserName, setAnalytics, setAccessToken } =
  authSlice.actions;
export default authSlice.reducer;
