import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: "",
    prepareHeaders: async (headers, { getState }) => {
        const state = getState();
        let access_token = state.auth?.access_token;

        if (access_token) {
            headers.set("Authorization", `Bearer ${access_token}`);
        } else {
            console.warn("No access token available!");
        }

        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        console.warn("Access token expired, attempting to refresh...");

        // const authData = JSON.parse(localStorage.getItem("persist:authPersist"));;
        // let access_token = authData.access_token;

        // if (!access_token) {
            console.error("Session has been Expired! Logging out.");
            api.dispatch({ type: "auth/logout" });
            return result;
        // }

        // try {
        //     // Call backend refresh token endpoint
        //     const refreshResponse = await fetch("http://localhost:5000/api/refresh-token", {
        //         method: "GET",
        //         headers: {
        //             "Content-Type": "application/json",
        //             Authorization: `Bearer ${access_token}`, // Use refresh token instead of access token
        //         },
        //         credentials: "include", // Ensure cookies are sent if required
        //     });

        //     if (refreshResponse.ok) {
        //         const { access_token } = await refreshResponse.json();

        //         // Store the new access token in Redux store and local storage
        //         api.dispatch({
        //             type: "auth/updateToken",
        //             payload: access_token,
        //         });
        //         localStorage.setItem("access_token", access_token);

        //         // Retry the original failed request with the new token
        //         result = await baseQuery(args, api, extraOptions);
        //     } else {
        //         console.error("Failed to refresh token. Redirecting to login.");
        //         api.dispatch({ type: "auth/logout" });
        //     }
        // } catch (error) {
        //     console.error("Error during token refresh:", error);
        //     api.dispatch({ type: "auth/logout" });
        // }
    }

    return result;
};

export const AnalyticsApi = createApi({
    reducerPath: "analyticsApiData",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        accountSummaries: builder.query({
            query: () => ({
                url: "https://analyticsadmin.googleapis.com/v1beta/accountSummaries",
                method: "GET",
            }),
        }),
        listProperties: builder.query({
            query: (accountId) => {
                const filter = `parent:accounts/${accountId}`;
                return {
                    url: "https://analyticsadmin.googleapis.com/v1beta/properties",
                    method: "GET",
                    params: { filter },
                };
            },
        }),
        dataStreams: builder.query({
            query: (propertyId) => ({
                url: `https://analyticsadmin.googleapis.com/v1beta/properties/${propertyId}/dataStreams`,
                method: "GET",
            }),
        }),
        getReports: builder.query({
            query: ({ reportParams }) => {
                const analyticsData = JSON.parse(localStorage.getItem("persist:authPersist"));
                const propertyId = analyticsData.property_id.replace(/"/g, "");
                
                return {
                    url: `https://analyticsdata.googleapis.com/v1beta/${propertyId}:runReport`,
                    method: "POST",
                    body: {
                        dateRanges: reportParams.dateRanges?.map((range) => ({
                            startDate: range.startDate,
                            endDate: range.endDate,
                        })),
                        metrics: reportParams.metrics?.map((metric) => ({
                            name: metric,
                        })),
                        dimensions: reportParams.dimensions?.map((dimension) => ({
                            name: dimension,
                        })),
                        dimensionFilter: reportParams.dimensionFilter?.andGroup?.expressions?.length
                            ? {
                                andGroup: {
                                    expressions: reportParams.dimensionFilter.andGroup.expressions.map((filter) => {
                                        const baseFilter = {
                                            filter: {
                                                fieldName: filter.notExpression ? filter.notExpression.filter.fieldName : filter.filter.fieldName,
                                                stringFilter: {
                                                    matchType: filter.notExpression
                                                        ? filter.notExpression.filter.stringFilter.match_type
                                                        : filter.filter.stringFilter.matchType,
                                                    value: filter.notExpression
                                                        ? filter.notExpression.filter.stringFilter.value
                                                        : filter.filter.stringFilter.value,
                                                    caseSensitive: filter.notExpression
                                                        ? filter.notExpression.filter.stringFilter.case_sensitive || false
                                                        : filter.filter.stringFilter.case_sensitive || false,
                                                },
                                            },
                                        };
        
                                        return filter.notExpression ? { notExpression: baseFilter } : baseFilter;
                                    }),
                                },
                            }
                            : undefined,
                        orderBys: reportParams.orderBys?.length
                            ? reportParams.orderBys.map((order) => ({
                                metric: {
                                    metricName: order.metric.metricName,
                                },
                                desc: order.desc ?? false, // Default to ascending if not specified
                            }))
                            : undefined,
                        limit: reportParams.limit || 1000,
                    },
                };
            },
        }),        
        realTimeReport: builder.query({
            query: ({ propertyId, dimensions, metrics }) => ({
                url: `https://analyticsdata.googleapis.com/v1beta/${propertyId}:runRealtimeReport`,
                method: "POST",
                body: {
                    dimensions: dimensions?.map((dimension) => ({ name: dimension })),
                    metrics: metrics?.map((metric) => ({ name: metric })),
                },
            }),
        }),        
        getSearchConsoleStats: builder.query({
            queryFn: async ({ reportParams, limit = 10 }, _queryApi, _extraOptions, fetchWithBQ) => {
                const analyticsData = JSON.parse(localStorage.getItem("analyticsData"));
                const domainStreamUrlFiltered = analyticsData.stream_name
                .replace(/^(https?:\/\/)?(www\.)?([^/]+)(\/.*)?$/i, "$3");            
                const domainStreamUrl = `sc-domain:${domainStreamUrlFiltered}`;
        
                const urls = [
                    domainStreamUrl,
                    `https://${domainStreamUrlFiltered.replace(/^(https?:\/\/([wW]{3}\.)?)?/, "")}`,
                    `https://www.${domainStreamUrlFiltered.replace(/^(https?:\/\/([wW]{3}\.)?)?/, "")}`,
                    `http://${domainStreamUrlFiltered.replace(/^(https?:\/\/([wW]{3}\.)?)?/, "")}`,
                    `http://www.${domainStreamUrlFiltered.replace(/^(https?:\/\/([wW]{3}\.)?)?/, "")}`,
                ];
        
                const startDate = reportParams[0].dates[0]?.startDate || "yesterday";
                const endDate = reportParams[0].dates[0]?.endDate || "today";
        
                let lastError = null;
        
                for (const url of urls) {
                    try {
                        const response = await fetchWithBQ({
                            url: `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(url)}/searchAnalytics/query`,
                            method: "POST",
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("googleAccessToken")}`, // Replace with actual token logic
                                "Content-Type": "application/json",
                            },
                            body: {
                                startDate,
                                endDate,
                                dimensions: ["query"],
                                rowLimit: limit,
                            },
                        });
        
                        if (response.error) {
                            console.warn(`Failed for URL: ${url}`, response.error);
                            lastError = response.error;
                            continue;
                        }
        
                        return { data: response.data };
                    } catch (error) {
                        console.warn(`Exception for URL: ${url}`, error);
                        lastError = { status: 500, message: error.message || "Unknown error occurred" };
                    }
                }
        
                return { error: lastError || { status: 404, message: "No valid response from Search Console API" } };
            },
        }),        
        
    }),
});

export const {
    useGetSearchConsoleStatsQuery,
    useAccountSummariesQuery,
    useListPropertiesQuery,
    useDataStreamsQuery,
    useGetReportsQuery,
    useRealTimeReportQuery,
} = AnalyticsApi;
