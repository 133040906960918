/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

const Table = ({
  data = [],
  columns = [],
  footerText,
  title,
  limit = 5,
  notFound,
  hideFooter,
  isLoading,
}) => {
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    const newFilteredData = data.slice(0, limit);
    if (JSON.stringify(filterData) !== JSON.stringify(newFilteredData)) {
      setFilterData(newFilteredData);
    }
  }, [data, limit]);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterData(data.slice((page - 1) * limit, page * limit));
  };

  const Pagination = () => {
    const totalPages = Math.ceil(data.length / limit);
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages.map((p, i) => (
      <li
        key={i}
        onClick={() => handlePageChange(p)}
        className={p === page ? "active" : ""}
      >
        {p}
      </li>
    ));
  };

  return (
    <div
      className={`table-wrapper ${filterData.length < 1 ? "not-found" : ""}`}
    >
      {title && <div className="table-header">{title}</div>}
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              {columns
                ? columns.map(({ title, key, width, align }, i) => (
                    <th
                      key={i}
                      style={{ width: width ?? "", textAlign: align ?? "" }}
                    >
                      {title}
                    </th>
                  ))
                : ""}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <></>
            ) : (
              <>
                {filterData.length > 0 &&
                  filterData.map((item, index) => (
                    <tr key={index}>
                      {columns.map(({title, key, render, align }, i) => (
                        <td style={{ textAlign: align ?? "" }} key={i} data-heading={title}>
                          {render ? render(item) : item[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      {
        isLoading && <div className="table-loading"></div>
      }
      {!isLoading && filterData.length < 1 && (
        <div className="table-data-not-found">
          <span>{notFound || "No Activity During This Period."}</span>
        </div>
      )}
      {hideFooter ? (
        ""
      ) : (
        <div className="table-footer">
          <p className="info">{footerText}</p>
          <ul className="table-pagination">
            {data && data.length > limit ? <Pagination /> : ""}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Table;
