import React, { useState, useEffect } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const DatePickerInput = ({ onDateChange }) => {
  // Calculate the last 7 days range
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const savedDates = localStorage.getItem("dateRange");
  const initialDates = savedDates
    ? savedDates.split(',').map((date) => new Date(date)) 
    : [sevenDaysAgo, today];

  // Set the default value to the initialDates
  const [value, setValue] = useState(initialDates);

  // Save the selected dates to localStorage and trigger onDateChange on value change
  useEffect(() => {
    localStorage.setItem("dateRange", JSON.stringify(value));
    onDateChange(value);
  }, [value, onDateChange]);

  const handleViewStats = () => {
    onDateChange(value);
  };

  return (
    <div className="range-date-picker">
      <DateRangePicker onChange={setValue} value={value} maxDate={today} />
      <button className="btn" onClick={handleViewStats}>
        View Stats
      </button>
    </div>
  );
};

export default DatePickerInput;
