import React from "react";

const Widget = ({ title, children, footerText, noSpacing, headerRight }) => {
  return (
    <div className={`widget ${noSpacing ? "no-space" : ""}`}>
      <div className="widget-header">
        <div className="title">{title}</div>
        <div>{headerRight}</div>
      </div>
      <div className="widget-body">{children}</div>
      {footerText && <div className="widget-footer">{footerText}</div>}
    </div>
  );
};

export default Widget;
