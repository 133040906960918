import React, { useEffect, useState } from "react";
import { useAccountSummariesQuery, useListPropertiesQuery, useDataStreamsQuery } from "api/analytics";
import Select from "react-select";
import { setAnalytics } from "features/slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const SelectPropertyId = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const accountSummariesResult = useAccountSummariesQuery();
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedStream, setSelectedStream] = useState(null);

    const { data: propertiesData } = useListPropertiesQuery(
        selectedAccount?.value?.replace("accounts/", "") || "",
        { skip: !selectedAccount }
    );

    const { data: streamsData } = useDataStreamsQuery(
        selectedProperty?.value?.replace("properties/", "") || "",
        { skip: !selectedProperty }
    );

    const [accounts, setAccounts] = useState([]);
    const [properties, setProperties] = useState([]);
    const [dataStreams, setDataStreams] = useState([]);

    useEffect(() => {
        if (accountSummariesResult.data) {
            const accounts = accountSummariesResult.data.accountSummaries.map((account) => ({
                value: account.account,
                label: account.displayName,
            }));
            setAccounts(accounts);
        } else if (accountSummariesResult.error) {
            navigate("/login");
        }
    }, [accountSummariesResult, navigate]);

    useEffect(() => {
        if (propertiesData) {
            const properties = propertiesData.properties.map((property) => ({
                value: property.name,
                label: property.displayName,
            }));
            setProperties(properties);
        } else {
            setProperties([]);
        }
    }, [propertiesData]);

    useEffect(() => {
        if (streamsData) {
            const streams = streamsData.dataStreams.map((stream) => ({
                value: stream.name,
                label: stream.displayName,
                url: stream.webStreamData.defaultUri,
            }));
            setDataStreams(streams);
        } else {
            setDataStreams([]);
        }
    }, [streamsData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedStream && selectedProperty) {
            const analyticsData = {
                stream_id: selectedStream.value,
                property_id: selectedProperty.value,
                stream_name: selectedStream.label,
                stream_url: selectedStream.url,
                property_name: selectedProperty.label,
            };
    
            // Save data to localStorage
            localStorage.setItem("analyticsData", JSON.stringify(analyticsData));
    
            // Dispatch data to Redux store
            dispatch(setAnalytics(analyticsData));
        }
    };
    

    return (
        <div className="select-account-wrapper">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Select Account</label>
                    <Select
                        value={selectedAccount}
                        options={accounts}
                        onChange={setSelectedAccount}
                    />
                </div>
                <div className="form-group">
                    <label>Select Property</label>
                    <Select
                        value={selectedProperty}
                        options={properties}
                        onChange={setSelectedProperty}
                        isDisabled={!selectedAccount}
                    />
                </div>
                <div className="form-group">
                    <label>Select Data Stream</label>
                    <Select
                        value={selectedStream}
                        options={dataStreams}
                        onChange={setSelectedStream}
                        isDisabled={!selectedProperty}
                    />
                </div>
                <button
                    type="submit"
                    disabled={!selectedStream || !selectedProperty}
                    className="btn primary-btn"
                >
                    Select Profile
                </button>
            </form>
        </div>
    );
};

export default SelectPropertyId;
