import React, { useState } from "react";

const Tabs = ({ data, footerText }) => {
  const [activeTab, setActiveTab] = useState("tab0");
  return (
    <div className="tabs">
      <ul className="tabs__list">
        {data?.map((tab, i) => {
          return (
            <li
              onClick={() => setActiveTab("tab" + i)}
              className={`tabs__item ${
                activeTab === "tab" + i ? "active" : ""
              }`}
              key={i}
            >
              {tab?.icon} <span>{tab?.title}</span>
            </li>
          );
        })}
      </ul>
      <div className="tabs__content">
        {data?.map((tab, i) => {
          return (
            <div
              className={`tabs__item ${
                activeTab === "tab" + i ? "active" : ""
              }`}
              key={i}
            >
              {tab?.content}
            </div>
          );
        })}
      </div>
      <div className="tabs-footer">{footerText ?? ""}</div>
    </div>
  );
};

export default Tabs;
