import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/slices/authSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { Api } from "api";
import { AnalyticsApi } from "api/analytics";

const authPersistConfig = {
  key: "authPersist",
  storage,
};

const authPersistReducer = persistReducer(authPersistConfig, authReducer);

export const store = configureStore({
  reducer: {
    apiData: Api.reducer,
    analyticsApiData: AnalyticsApi.reducer,
    auth: authPersistReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([Api.middleware, AnalyticsApi.middleware]),
});

setupListeners(store.dispatch);
