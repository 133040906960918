/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/analytify-logo.svg";
import { slideToggle } from "../slideToggle";
import { useSelector } from "react-redux";
import Gravatar from "react-gravatar";
import { MdOutlineLogout } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { RiDashboardLine } from "react-icons/ri";

const Header = ({ routes }) => {
  const { user } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const menuRef = useRef(null);
  const toggleMenu = () => {
    slideToggle(menuRef.current);
  };


  const checkActive = (route) => {
    if (pathname.includes(route?.path)) {
      return "active";
    } else if (route?.subMenu) {
      return route?.subMenu.some((e) => pathname.includes(e.path))
        ? "active"
        : "";
    }
    return "";
  };

  return (
    <header className="header">
      <div className="header-top">
        <div className="wpb-container">
          <button onClick={toggleMenu} className="menu-btn"></button>
          <Link to="/" className="brand-logo">
            <img src={Logo} alt="Analytify" />
          </Link>
          <div to="#" className={`user-profile`}>
            {user?.picture ? (
              <img src={user?.picture} alt={user?.name} />
            ) : (
              <Gravatar email={user?.email} />
            )}
            <span className="user-name">{user ? user?.name : "Account"}</span>
            <ul className="user-dropdown">
              <li>
                <Link to="/profile">
                  <>
                    <CgProfile /> Profile
                  </>
                </Link>
              </li>
              {user?.role === "admin" && (
                <li>
                  <Link to="/admin/dashboard">
                    <>
                      <RiDashboardLine /> Dashboard
                    </>
                  </Link>
                </li>
              )}
              <li>
                <Link to="/logout">
                  <>
                    <MdOutlineLogout /> Logout
                  </>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="wpb-container">
          <ul ref={menuRef} className="main-menu">
            {routes.map((route, index) => (
              <li
                className={`${checkActive(route)} ${
                  route?.hideInMenu ? "hide" : ""
                }`}
                key={index}
              >
                <Link  to={route?.path || "#"}>
                  <span>{route.label}</span>
                  <span>{route.desc}</span>
                </Link>
                {route.subMenu && (
                  <ul className="sub-menu">
                    {route.subMenu.map((subRoute, index) => (
                      <li
                        className={pathname === subRoute.path ? "active" : ""}
                        key={index}
                      >
                        <Link
                          to={subRoute?.path || "#"}
                        >
                          <span>{subRoute.label}</span>
                          <span>{subRoute.desc}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
